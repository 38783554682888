.user-list-container {
  padding: 10px;
}

.user-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #463f3f;
  color: #c0c0c0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.user-table-header button {
  background: #fff;
  border: 1px solid #3b3535;
  color: #808080;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}

.table-div {
  text-align: center;
  font-size: 18px;
}

.red-cross {
  color: red;
}

/* Did not used */
.user-list-table {
  width: 100%;
  overflow: auto;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table th,
.table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.table th {
  background-color: #2C2C2C;
  color: #C0C0C0;
}
.table tbody tr:hover {
  background-color: #A8A9AD;
}
.create-user-btn{
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
}
.create-user-btn:hover{
  background-color: #808080;
  color: black;
}
.registartion-form{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -55%);
  width: 100%;
  height: 90%;
  z-index: 998;
  display: flex;
  justify-content: center;
}

.deatils-form{
  position: absolute;
  top: 6.5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.required-field::before {
  content: "*";
  color: red;
  font-size: medium;
}




