.prebuild-component {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.Delete-row,
.Export-Data,
.Update-row,
.Add-Data{
  background-color: #3B3535;
  color: white;
  font-size: 13px;
  margin:3px;
  padding: 8px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.Delete-row:hover,
.Export-Data:hover,
.Update-row:hover,
.Add-Data:hover{
  background-color: #808080;
  color: black;
}
.upload_btn3 {
  background-color: #8F8F8F;
  color: black;
  font-size: 13px;
  margin: 3px;
  padding: 9px;
  margin-left: 0px;
}
.custom_file_input2 {
  background-color: #3B3535;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  left: 30px;
    color: white;
      font-size: 13px;
    margin: 3px;
}
