.update-user-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

#roles {
  border: 1px solid #fff;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}
#roles option {
  background-color: #f4f4f4;
  color: #333;
  padding: 5px;
  border-radius: 5px;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.checkbox-container input[type="checkbox"] {
  margin-right: 20px;
  transform: scale(1.5);
}
.checkbox-container label {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: -3px;
}
.swal-container {
  z-index: 99999;
}