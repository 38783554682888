.oneoff-component {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.input-search {
    padding: 8px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-right: -5px;
}