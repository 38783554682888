#rightTable {
  width: 70%;
  height: 80%;
}

button {
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}

select {
  padding: 7px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.modal-form {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background-color: white;
  width: 800px;
  height: auto;
  padding: 20px;
  border: 1px solid #887a7a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-top: 50px;
  font-size: 12px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-input-button,
.add-row-button,
.copy-external-button,
.paste-external-button {
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
  padding: 8px;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
}

.delete-button {
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
  font-size: 12px;
  padding: 8px;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
}

.delete-button:hover {
  background-color: #808080;
  color: black;
}

.next-input-button:hover,
.add-row-button:hover,
.copy-external-button:hover,
.paste-external-button:hover {
  background-color: #808080;
  color: black;
}

.cancel-button-class {
  background-color: #D1D1D1 !important;
  color: #000000 !important;
  border-color: #D1D1D1 !important;
}

.copy-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.MuiCheckbox-root {
  margin-right: 100px;
}