.table-container2 {
  overflow-x: scroll;
}
.rate-input-btn {
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
}

.rate-input-btn:hover {
  background-color: #808080;
  color: black;
}