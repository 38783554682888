.create-form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.modal-contents {
    width: 80%;
    margin: 0 auto;
}

.form-divs {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    max-height: 450px;
    overflow-y: auto;
}

.modal-contents label {
    display: block;
    margin-bottom: 10px;
}

.modal-contents input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.modal-contents input[type="number"] {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.swal-container {
    z-index: 99999;
}