@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
input {
    font-family: "Poppins", sans-serif;
}
.login-container{
    width:100vw;
    height:100vh;
    display: flex;
    flex-direction: row;
}
.login-panels-container {
    width: 70%;
    background-image: linear-gradient(-45deg, #f2f3f4 0%, #a9a9a9 100%);
    transition: 1.8s ease-in-out;
    border-radius: 0px 0px 190%;
}

.login-image {
    width: 80%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
    margin-top: 50px;
}

.login-form-container {
    width: 60%;
    display: flex;
    justify-content: center;
}
.login {
    display: flex;
    flex-direction: column;
}

.logo-container {
    height: 50%
}

.logo {
    width: 400px;
    min-width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.login-form {
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 /2;
}

.login-title {
    font-size: 35px;
    color: #444;
    margin-bottom: 10px;
    margin-left: 0px;
}

.login-input-container {
  width: 100%;
  max-width: 450px;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 6.4px;
  position: relative;
}

.login-input-container .login-icon {
    color: #acacac;
    transition: 0.5s;
    font-size: 17px;
    margin-top: 17px;
    margin-left: 10px;
}

.login-input-container input {
    background: none;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 15px;
    color: #333;
}

.login-input-container input::placeholder {
    color: #aaa;
    font-weight: 500;
}
.login-password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    color: #acacac;
}

.signin-forgot-password-link {
    color: black;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
}
.login-btn {
    width: 150px;
    background-color: #3B3535;
    border: none;
    outline: none;
    height: 49px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;
    transition: 0.5s;
}
.login-btn:hover {
    background-color: #808080;
    color: black;
}

@media screen and (max-width:768px) {
    .login-container{
        flex-direction: column;
        align-items: center;
    }
    .login-panels-container{
        background-image: none;
        display: none;
    }

}

@media screen and (max-width:425px){
    .logo{
        width:300px
    }
}