.forgotpassword-content {
  padding: 50px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgotpassword-p{
  color: #555;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 14px;
}
.forgotpassword-inputContainer{
  width: 100%;
  max-width: 450px;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 6.4px;
  position: relative;
}
.forgotpassword-inputContainer .email-icon {
  color: #acacac;
  transition: 0.5s;
  font-size: 17px;
  margin-top: 17px;
  margin-left: 10px;
}
.forgotpassword-inputContainer input::placeholder {
  color: #aaa;
  font-weight: 500;
}
.forgotpassword-inputContainer input {
  background: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #333;
}
.forgot-button,
.reset-button {
  width: auto;
  background-color: #3B3535;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 20px 0;
  cursor: pointer;
  transition: 0.5s;
  padding:0px 20px;
}
.forgot-button:hover,
.reset-button:hover{
  background-color: #808080;
  color: black;
}
.forgotPassword-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 50px;
}
.forgot-password-link{   
  color:black;
  font-size: 13px;
}
.forgot-password-link:hover{   
  color: #000000;
  font-size: 13px;
}
a{
  text-decoration: none;
}
.resetpassword-inputContainer {
  background-color: #f0f0f0;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 15% 85%;
  position: relative;
}

.resetpassword-inputContainer input {
  background: none;
  outline: none;
  border: 1px solid #8080802e;
  font-weight: 400;
  font-size: 15px;
  color: #333;
  padding-left: 20px;
  border-radius: 3px;
  width: 500px;
  max-width: 450px;
  height: 55px;
}

.forgotPasswordimg {
  max-width: 100%;
  height: auto;
}