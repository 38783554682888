.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
}

.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}

.loading {
  width: 58px;
  height: 58px;
  border: 5px solid #000;
  border-bottom-color: #808080;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.outer-container {
  display: block;
  background-color: #bebebe86;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 13;
}