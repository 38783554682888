.modal-contents2 {
  width: 80%;
  margin: 0 auto;
}
.create-user-form {
  background-color: white;
  padding: 20px;
  border: 1px solid #887a7a;
  border-radius: 10px;
}
#roles {
  border: 1px solid #fff;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}
#roles option {
  background-color: #f4f4f4;
  color: #333;
  padding: 5px;
  border-radius: 5px;
}