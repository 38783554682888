.rate-input-modal {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.rate-input-modal-content {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 18%;
}
.rateinput-modal-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background-color: white;
  width: 800px;
  height: auto;
  padding: 20px;
  padding-top: 40px;
  border: 1px solid #887a7a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-top: 50px;
  font-size: 12px;
}

.table-container {
  overflow-x: auto;
  width: 100%;
  font-size: 12px;
  overflow: auto;
  height: 500px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.responsive-table th,
.responsive-table td {
  padding: 0px 10px;
  border: 1px solid #ddd;
  text-align: center;
  height: 25px;
  text-align: left;
}
.responsive-table th {
  background-color: #1b1212;
  color: white;
  font-weight: 500;
  padding: 15px;
  width: 160px;
}
.responsive-table td input{
  width: 100%;
  margin: 2px;
}
.responsive-table th:first-child {
  border-top-left-radius: 5px;
}

.responsive-table th:last-child {
  border-top-right-radius: 5px;
}
input[type="checkbox"] {
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
}
.responsive-table th input[type="checkbox"] {
  margin: 0 auto;
  display: block;
}
.rate-input-form-button {
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
  margin-top: 20px;
  padding: 8px;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
}

#calculationForm {
  height: 70vh;
  width: 97%;
}

.rate-input-form-button:hover {
  background-color: #808080;
  color: black;
}



.rate-form-custom-header {
  background-color: #1B1B1B !important;
  color: #C0C0C0 !important;
  font-weight: bold !important;
  cursor: pointer;
}

.rate-form-custom-header:hover {
  background-color: #1B1212 !important;
}

.select-dropdown {
  background-color: #E8E8E8;
  color: #000000;
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .responsive-table {
    overflow-y: auto;
  }
}