.modal {
  display: block;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  .modal-content {
    .modal-form {
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 40px;

      label {
        display: block;
        margin-bottom: 10px;

        input[type="text"],
        input[type="number"] {
          width: 100%;
          padding: 8px;
          margin-top: 4px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
        }
      }

      button {
        margin-top: 20px;
        padding: 10px 20px;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      .modal-form-btn {
        background-color: #3b3535;
        color: #c91919;
        cursor: pointer;

        &:hover {
          background-color: #808080;
          color: black;
        }
      }
    }

    .form-divs {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 20px;

      .subDiv {
        display: flex;
        flex-direction: column;
      }
    }

    .close {
      position: absolute;
      top: 0px;
      right: 10px;
      cursor: pointer;
      font-size: 40px;
      color: #aaa;

      &:hover,
      &:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}