.quote-container {
    padding: 20px;
    max-width: 800px;
    margin-top: 30px;
}

.columns-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    width: 70%;
}

.column-label {
    font-size: 16px;
    font-weight: 600;
}

.related-columns {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.related-columns-label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-right: 90px;
    padding-left: 20px;
}

.column * {
    padding: 2px;
}

.generate-quote {
    display: flex;
    justify-content: center;
    margin-top: 30px
}

.generate-quote-btn {
    padding: 10px 15px;
    background-color: #3B3535;
    color: #C0C0C0;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    margin-left: 10px;
}

.generate-quote-btn:hover {
    background-color: #808080;
    color: black;
}

/* Unused */
.quote-form {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    gap: 10px;
    width: 100%;
    align-items: center;
}

.quote-form-assign {
    display: grid;
    min-width: 800px;
    grid-template-columns: 1fr 2fr 2fr;
    align-items: center;
}

/* .quote-form label {
    font-size: 19px;
    font-weight: 600;
} */

.select-box {
    width: 100%;
    padding: 10px;
    outline: none;
}

.new-link {
    flex: 1;
    text-decoration: underline;
    font-size: 17px;
    margin-left: 20px;
    color: black;
    font-weight: 500;
    cursor: pointer;
}

.block-label {
    font-size: 19px;
    font-weight: 600;
}

.assign-button {
    padding: 10px 20px;
    background-color: #3B3535;
    color: #C0C0C0;
    cursor: pointer;
    margin-left: 30px;
}

.assign-button:hover {
    background-color: #808080;
    color: black;
}

.column2 {
    display: flex;
    flex-direction: column;
}