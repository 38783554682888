.input-wrapper {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin-left: 10px;

    .input-field {
        width: calc(100% - 80px);
        padding-right: 60px;
        box-sizing: border-box;
        font-size: 12px;
        border-radius: 2px;
        min-width: 240px;
        padding-top: 4.5px;
        padding-bottom: 4.5px;

        &:focus {
            outline: none;
        }
    }

    .input-length {
        position: relative;
        top: 50%;
        margin-left: -45px;
        transform: translateY(-50%);
        color: #aaa;
        font-size: 12px;
    }
}