.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 5px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}
.navbar-logo-container {
  margin-right: 20px;
}
.nav-logo {
  width: 50px;
  height: auto;
}
.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.navbar-link {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
  font-size: 14px;
  transition: color 0.3s ease;
}
.nav-link {
  transition: border-color 0.3s ease;
}
.navbar-active-link {
  border-bottom: #fff 3px solid;
  color: #A8A9AD;
}

.user-fullname {
  margin-left: 8px;
  font-size: 14px;
}