.calculation-custom-header {
  background-color: #1B1B1B !important;
  color: #C0C0C0 !important;
  font-weight: bold !important;
  cursor: pointer;
}
.calculation-custom-header:hover {
  background-color: #1B1212 !important;
}
.export-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
  padding: 8px;

  border: none;
  border-radius: 3px;
  margin-left: 10px;
}

.calculationPage-total label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-left: 5px;
}

.calculationPage-total input[type="text"] {
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 5px;
}

.view-breakup-btn {
  background-color: #3B3535;
  color: #C0C0C0;
  cursor: pointer;
  padding: 8px;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
}

.export-btn:hover,
.view-breakup-btn:hover {
  background-color: #808080;
  color: black;
}

.modal-form {
  background-color: #f6f6f6;
}

.modal-content {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 18%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.model-form-content-style {
  margin-top: 20px;
}

.heading {
  text-align: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.cell {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 16px;
}

.currency-cell {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.currency-symbol {
  flex-shrink: 0;
  font-size: 16px;
}

.currency-value {
  text-align: right;
  flex-grow: 1;
  font-size: 14px;
}